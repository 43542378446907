import React, { useState } from 'react';

import styled from 'styled-components';

const SB_WIDTH = 325;
const COLOR_CHIP = 36;

const StyledLogo = styled.div`

background-image: var(--logo);
@media (max-width: 768px) {
    background-image: url(/${(p) => (p.theme === 'dark' ? 'catch_invert' : 'catch')}.svg)
},
`;

const StyledURL = styled.div`
    font-size: 24px;
    bottom: 15%;
    @media (max-width: 768px) {
        font-size: 16px;
        bottom: 5%;
    },
`;

const StyledMainText = styled.div`
  font-size: ${(p) => (p.font === 'mono' ? 36 : (p) => (p.font === 'hand' ? 56 : 48))}px;
  @media (max-width: 768px) {
    line-height: 1.15;
    font-size: ${(p) => (p.font === 'mono' ? 22 : (p) => (p.font === 'hand' ? 36 : 26))}px;
  }
`;
const StyledBigIcon = styled.div`
  height: 125px;
  width: 150px;
  @media (max-width: 768px) {
    height: 50px;
    width: 75px;
    margin-bottom: 24px !important;
  }
`;

const StyledMain = styled.div`
  left: ${SB_WIDTH}px;
  right: 24px;
  top: 24px;
  bottom: 24px;
  position: absolute;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column !important;
    flex-flow: column !important;
    left: 0px;
    right: 0px;

    top: 0;
    bottom: 50%;
    border-radius: 0px;
  }
`;

const StyledSidebar = styled.div`
  width: ${SB_WIDTH}px;
  left: 0px;
  top: 60px;
  bottom: 0px;
  position: absolute;
  overflow: scroll;
  @media (max-width: 768px) {
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 50%;
    width: auto;
    top: auto;
  }
`;

const icons = [
  'target.svg',
  'house.svg',
  'Suit Case.svg',
  'Wedding Cake.svg',
  'Animal Food.svg',
  'Tools.svg',
  'Car.svg',
  'Computer.svg',
  'Price Tag.svg',
  'Gift.svg',
  'Breif Case.svg',
  'vault.svg',
  '529CollegeSavings.svg',
  'LifeInsurance.svg',
  'Address book.svg',
  'LockedPadlock.svg',
  'Ambulance.svg',
  'Map.svg',
  'Announcement.svg',
  'Mountain-1.svg',
  'Astronaut.svg',
  'Mountain.svg',
  'Bank.svg',
  'Official.svg',
  'Bonsai.svg',
  'Outline.svg',
  'Broken Link.svg',
  'Party.svg',
  'Calendar.svg',
  'Piggy Bank-1.svg',
  'Clipboard.svg',
  'Piggy Bank.svg',
  'Coin.svg',
  'Plan.svg',
  'Computer.svg',
  'Retirement.svg',
  'Cuckoo clock.svg',
  'Rocket ship.svg',
  'DentalInsurance.svg',
  'Securities Employee.svg',
  'DisabilityInsurance.svg',
  'Stopwatch.svg',
  'Editing Paychecks.svg',
  'StudentLoanRepayment.svg',
  'EmergencySavings-1.svg',
  'Sunshine and Rainbows.svg',
  'EmergencySavings.svg',
  'T-shirt.svg',
  'Family Leave.svg',
  'Tax payment.svg',
  'FamilyLeave.svg',
  'TaxWithholding.svg',
  'Flowers.svg',
  'Telescope.svg',
  'Guide.svg',
  'Thumbs up.svg',
  'Health Expenses.svg',
  'TimeOff.svg',
  'Health exchange.svg',
  'Tools.svg',
  'HealthExpenses.svg',
  'Trading.svg',
  'HealthInsurance.svg',
  'UnlockedPadlock.svg',
  'Hospital.svg',
  'Van.svg',
  'ID card.svg',
  'VisionInsurance.svg',
  'Leaves.svg',
  'Weathervane.svg',
];

const hex = {
  light: ['#FFFFFF', '#FFE6D1', '#E6F0F0', '#E8EDFF'],
  lightColors: ['peach', 'peach', 'sage', 'flare'],
  dark: ['#1C1C1E', '#00746F', '#3A56A6', '#D05052', '#352A75'],
  darkColors: ['peach', 'algae', 'flare', 'coral', 'purple'],
};

const fonts = [
  {
    type: 'sans',
    family: 'var(--ff-base)',
  },
  {
    type: 'mono',
    family: 'Space Grotesk',
  },
];

const Section = ({ children }) => {
  return (
    <div
      style={{
        borderBottom: '1px solid #eeeeee00',
        padding: '16px 0 16px ',
      }}
    >
      {children}
    </div>
  );
};

const ColorChip = ({ hex, onClick, selected }) => {
  return (
    <div
      onClick={onClick}
      style={{
        height: COLOR_CHIP,

        borderRadius: 4,
        background: hex,
        marginRight: 2,
        flex: 1,
        display: 'inline-block',
        padding: 0,
        cursor: 'pointer',
        border: `2px solid ${selected ? 'var(--color-flare-primary)' : 'var(--fga)'} `,
      }}
    />
  );
};

const Chip = ({ children, style, onClick, selected, bg }) => {
  return (
    <div
      onClick={onClick}
      style={{
        borderRadius: 99,
        fontWeight: 600,
        background: `${selected ? (bg ? bg : '#333537') : 'transparent'}`,
        padding: '10px 10px 9px',
        margin: '8px 0',
        color: `${selected ? '#fff' : 'var(--fg)'}`,
        ...style,
        cursor: 'pointer',
        lineHeight: 1,
        flexShrink: 0,
        fontSize: 15,
        flex: 1,
        textAlign: 'center',
      }}
    >
      {children}
    </div>
  );
};

const Sidebar = ({ children }) => {
  return (
    <StyledSidebar
      style={{
        boxSizing: 'border-box',
        padding: 24,
        paddingTop: 0,
      }}
    >
      {children}
    </StyledSidebar>
  );
};

const Main = ({ children, style }) => {
  return (
    <StyledMain
      style={{
        transition: '.25s ease-in-out',
        position: 'absolute',
        backgroundColor: '#ecedef',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 700,
        textAlign: 'center',
        boxSizing: 'borderBox',
        padding: 36,
        boxShadow: '0 10px 200px -20px #00000022',
        lineHeight: 1.3,
        ...style,
      }}
    >
      {children}
    </StyledMain>
  );
};

const SmallIcon = ({ children, style, selected, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        height: 45,
        borderRadius: 2,
        width: 60,
        margin: 1,
        backgroundSize: '60% 60%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        border: `2px solid ${selected ? 'var(--color-flare-primary)' : 'transparent'}`,
        cursor: 'pointer',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

const BigIcon = ({ children, style }) => {
  return (
    <StyledBigIcon
      style={{
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        marginBottom: 36,
        ...style,
      }}
    >
      {children}
    </StyledBigIcon>
  );
};

function App() {
  const [phrase, setPhrase] = useState('your catchphrase goes here');
  const [font, setFont] = useState(fonts[0].family);
  const [theme, setTheme] = useState('light');
  const [bg, setBG] = useState(hex[theme][0]);
  const [mode, setMode] = useState('color');
  const [iconColor, setIconColor] = useState('peach');
  const [icon, setIcon] = useState('Map.svg');
  return (
    <div className="App">
      <Main
        style={{
          backgroundColor: bg,
        }}
      >
        <BigIcon
          style={{
            backgroundImage: `url("https://s.catch.co/img/goals/catchphrase/${theme}/${
              mode === 'flat' ? 'flat' : iconColor
            }/${icon}")`,
          }}
        />
        <StyledMainText
          font={font}
          style={{
            fontFamily: font,
            color: theme === 'dark' ? 'white' : 'black',

            maxWidth: '100%',
          }}
        >
          {phrase}
        </StyledMainText>
        <StyledURL
          style={{
            position: 'absolute',
            margin: 'auto',

            fontWeight: 400,
            color: theme === 'dark' ? 'white' : 'black',
          }}
        >
          catch.co
        </StyledURL>
      </Main>
      <Sidebar>
        <Section>
          <StyledLogo
            theme={theme}
            style={{
              height: 60,
              width: SB_WIDTH,

              marginBottom: 24,
              backgroundPosition: '24px center',
              backgroundSize: 'auto 24px',
              backgroundRepeat: 'no-repeat',

              position: 'fixed',
              top: 0,
              left: 0,
            }}
          ></StyledLogo>
          <input
            style={{
              width: '100%',
              background: 'var(--fgaa)',
              color: 'var(--fg)',
              fontSize: 16,
              borderRadius: 4,
              fontFamily: 'Segment',
              border: 'none',
              boxSizing: 'border-box',
              padding: '8px 12px',
            }}
            value={phrase}
            onChange={(e) => setPhrase(e.target.value)}
          />
          <br />

          <div className="flex">
            {fonts.map((item) => (
              <Chip
                key={item.type}
                selected={font === item.family}
                onClick={() => setFont(item.family)}
                style={{ fontFamily: item.family }}
                bg={theme === 'dark' && bg}
              >
                {item.type}
              </Chip>
            ))}
          </div>
        </Section>
        <Section>
          <div className="flex">
            {hex.light.map((item, idx) => (
              <ColorChip
                key={item}
                selected={item === bg}
                onClick={() => {
                  setBG(item);
                  setTheme('light');
                  setIconColor(hex.lightColors[idx]);
                }}
                hex={item}
              />
            ))}
            {hex.dark.map((item, idx) => (
              <ColorChip
                key={item}
                selected={item === bg}
                onClick={() => {
                  setBG(item);
                  setTheme('dark');
                  setIconColor(hex.darkColors[idx]);
                }}
                hex={item}
              />
            ))}
          </div>
        </Section>

        <Section>
          <div className="flex">
            <Chip
              bg={theme === 'dark' && bg}
              selected={mode === 'color'}
              onClick={() => setMode('color')}
            >
              Color
            </Chip>
            <Chip
              bg={theme === 'dark' && bg}
              selected={mode === 'flat'}
              onClick={() => setMode('flat')}
            >
              Flat
            </Chip>
          </div>

          <div className="flex" style={{ flexWrap: 'wrap' }}>
            {icons.map((item) => (
              <SmallIcon
                key={icon}
                selected={icon == item}
                style={{
                  backgroundColor: bg,
                  backgroundImage: `url("https://s.catch.co/img/goals/catchphrase/${theme}/${
                    mode === 'flat' ? 'flat' : iconColor
                  }/${item}"`,
                }}
                onClick={() => setIcon(item)}
              />
            ))}
          </div>
        </Section>
      </Sidebar>
    </div>
  );
}

export default App;
